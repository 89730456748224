<template>
    <section class="tables">
    
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                Liste des unités
              </div>
              <!-- <nav aria-label="breadcrumb" class=" float-right">
                <ol class="breadcrumb">
                  <router-link to="addemplacements">
                    <li class="breadcrumb-item btn btn-primary">
                      Ajouter un emplacement
                    </li>
                  </router-link>
                </ol>
              </nav> -->
              <div class="table-responsive">
                <input
                  value=""
                  class="form-control search-input"
                  placeholder="Recherche par code"
                  type="text"
                  v-model="search"
                />
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">grandeur physique</th>
                      <th scope="col">nom</th>
                      <th scope="col">code</th>

                   </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="col">Longueur </th>
                      <th scope="col">Métres</th>
                      <th scope="col">M</th>

                   </tr>
                   <tr>
                      <th scope="col">Masse </th>
                      <th scope="col">kilogramme </th>
                      <th scope="col">Kg</th>

                   </tr>
                   <tr>
                      <th scope="col">température  </th>
                      <th scope="col">kelvin </th>
                      <th scope="col">T</th>

                   </tr>
                   <tr>
                      <th scope="col">Chaleur</th>
                      <th scope="col">Joule</th>
                      <th scope="col">J</th>

                   </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { HTTP } from '@/axios';
  import Swal from "sweetalert2";
  import Toast from "sweetalert2";
  export default {
    name: "basicTables",
    data() {
      return {
        emplacements: [],
        search: "",
      };
    },
  
    created() {
      this.getemplacements();
    },
    computed: {
      searchFunction() {
        return this.emplacements.filter((item) => {
          return (
            item.code
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
          );
        });
      },
    },
    methods: {
      getemplacements() {
        HTTP
          .get("getemplacements")
          .then((response) => {
            this.emplacements = response.data;
          })
          .then((err) => console.log(err));
      },
      choose(id) {
        this.$router.push("editemplacements/" + id);
      },
      deletemplacement(id) {
        Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Vous voulez supprimé l'emplacement",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
            HTTP
              .delete("deleteemplacement/" + id)
              .then((response) => {
                //this.$htmlToPaper("printNow");
  
                Toast.fire({
                  position: "top-center",
                  icon: "success",
                  title: "emplacement supprimé",
                });
  
                this.getemplacements();
              });
          }
        });
      },
    },
  };
  </script>
  